import type r4 from 'fhir/r4'

import type { Version } from '@/utils/autoRendererVersions'

export const tables = {
  Resources: 'Resources',
  ResourceVersions: 'ResourceVersions',
  ResourceVersionIterations: 'ResourceVersionIterations',
  Applications: 'Applications',
} as const

export const resourceTypes = {
  Questionnaire: 'Questionnaire',
} as const

export type Database = PouchDB.Database
export type TableName = keyof typeof tables
export type ResourceType = keyof typeof resourceTypes
export type RecordID = string

export interface DatabaseRecord {
  _id: RecordID
  slug: string
  createdAt: string
  updatedAt?: string
}

export type Createable<T> = Omit<T, keyof DatabaseRecord>

export interface Resource extends DatabaseRecord {
  type: ResourceType
  label: string
  currentVersionID?: RecordID
  legacyStorageKey?: string
  legacyLibraryVersion?: Version
  legacyResponse?: r4.QuestionnaireResponse
  legacyConfig?: object
}

export interface ResourceVersion extends DatabaseRecord {
  resourceID: RecordID
  version: string
  url: string
  label?: string
  description?: string
  resource: object
}
export interface ApplicationDependency {
  version: string
  config?: object
}

export interface Application extends DatabaseRecord {
  label: string
  dependencies: Record<string, ApplicationDependency>
  resources: RecordID[]
}
export type ResourceDocument = PouchDB.Core.ExistingDocument<Resource>
export type ResourceVersionDocument =
  PouchDB.Core.ExistingDocument<ResourceVersion>
export type ApplicationDocument = PouchDB.Core.ExistingDocument<Application>
export type ApplicationDocumentWithRelated = PouchDB.Core.ExistingDocument<
  Application & {
    resourceDocuments: ResourceDocument[]
  }
>

export type Migration = DatabaseRecord

type PouchDBDocument = { _id: string }

/**
 * Type guard for PouchDB documents.
 */
export const isDocument = (subject: unknown): subject is PouchDBDocument => {
  return (
    subject !== null &&
    typeof subject === 'object' &&
    '_id' in subject &&
    typeof subject._id === 'string' &&
    subject._id.length > 0
  )
}
